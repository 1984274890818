export enum ApiSlug {
  // Auth
  LOGIN = "/auth/login",
  SIGN_UP = "/auth/register",
  REFRESH_TOKEN = "/auth/refresh-token",
  FORGOT_PASSWORD = "/auth/forgot-password",
  VERIFY_OTP = "/auth/verify-otp",
  RESET_PASSWORD = "/auth/reset-password",
  INVITE_EMPLOYEE = "/auth/invite-employee",
  ACTIVATE_EMPLOYEE = "/auth/activate-employee",

  // Postal codes
  GET_CODES = "/postal-code",

  // Stats
  ORDER_STATS_DAY = "/stats/order-stats-day",
  OVERALL_STATS = "/stats/overall",
  GET_COMPANY = "/stats/get-company",

  // user
  CHANGE_EMAIL = "/user/change-email",
  CHANGE_EMAIL_VERIFY = "/user/change-email-verify",
  CHANGE_PASSWORD = "/user/change-password",
  CHANGE_NAME = "/user/change-name",
  CHANGE_ADDRESS = "/user/change-address",
  CHANGE_ALLOWANCE = "/user/change-allowance",

  GET_COMPANY_STAFFS = "/user/team",
  DISABLE_EMPLOYEE = "/user/disable",

  USER_INFO = "/user/info",
  MARK_COMPLETE = "/user/mark-complete",

  // Payment info
  NEW_EDIT_PAYMENT_INFO = "/payment-info",
  DELETE_PAYMENT_INFO = "/payment-info/disable",

  GET_ALL_ADDITIONS = "/menu/addition",
  GET_MENU_ITEMS = "/menu/item",
  GET_MENU_ITEMS_BY_DAY = "/menu/item/day",

  // Delivery info
  DELIVER_PRICE = "/delivery-info",

  // orders
  GET_ALL_ORDERS = "/order",
  GET_SINGLE_ORDER = "/order/single",
  ONE_TIME_ORDER = "/order/one-time",
  EDIT_ONE_TIME_ORDER = "/order/one-time/{order_id}",
  CANCEL_ONE_TIME_ORDER = "/order/one-time/cancel/{order_id}",
  EDIT_RECURRING_ORDER = "/order/recurring",
  CANCEL_RECURRING_ORDER = "/order/recurring/cancel/{recurring_order_id}",
  RECURRING_ORDER = "/order/recurring",
  MEETING_ORDER = "/order/meeting-order",

  // payments
  CREATE_SUBSCRIPTION = "/payments/create-subscription",
  DELETE_SUBSCRIPTION = "/payments/delete-subscription",

  GET_PAYMENTS = "/payments/user",
  GET_COMPANY_PAYMENTS = "/payments/company",

  // Notifications
  GET_ALL_NOTIFICATIONS = "/notifications",
  CLEAR_NOTIFICATIONS = "/notifications/clear",
  MARK_READ_NOTIFICATIONS = "/notifications/mark-read",
}
