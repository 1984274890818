import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"

export const deliverInfoServiceApi = createApi({
  reducerPath: "deliverInfoService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getDeliverPrice: builder.query({
      query: ({ data }) => ApiSlug.DELIVER_PRICE,
      providesTags: ["deliver_price"],
    }),
  }),
})

export const { useLazyGetDeliverPriceQuery } = deliverInfoServiceApi
