import React from "react"
import { Navigate, useRoutes } from "react-router-dom"
import { UrlSlugType } from "../utils/enums/UrlSlug"

// Layout
const DashboardLayout = React.lazy(() => import("../layouts/private"))

// pages
const HomePage = React.lazy(() => import("../pages/private/company/Home"))
const PaymentsPage = React.lazy(
  () => import("../pages/private/company/Payments"),
)
const TeamPage = React.lazy(() => import("../pages/private/company/Team"))
const SettingsPage = React.lazy(
  () => import("../pages/private/common/Settings"),
)

const CompanyRoutes = () =>
  useRoutes([
    {
      element: <DashboardLayout />,
      children: [
        { path: UrlSlugType.HOME, element: <HomePage /> },
        { path: UrlSlugType.PAYMENTS, element: <PaymentsPage /> },
        { path: UrlSlugType.TEAM, element: <TeamPage /> },
        { path: UrlSlugType.SETTINGS, element: <SettingsPage /> },
      ],
    },
    { path: "*", element: <Navigate to={UrlSlugType.HOME} /> },
  ])

export default CompanyRoutes
