import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"
import { objectToSearchParams } from "../utils/helpers/common"

export const statsServiceApi = createApi({
  reducerPath: "statsService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getOverallStats: builder.query({
      query: () => ApiSlug.OVERALL_STATS,
      providesTags: ["overallStats"],
    }),
    getCompanyStats: builder.query({
      query: ({ data }) =>
        `${ApiSlug.ORDER_STATS_DAY}?${objectToSearchParams(data)}`,
      providesTags: ["companyStats"],
    }),
    getCompany: builder.query({
      query: ({ data }) =>
        `${ApiSlug.GET_COMPANY}?${objectToSearchParams(data)}`,
      providesTags: ["company"],
    }),
  }),
})

export const {
  useLazyGetOverallStatsQuery,
  useLazyGetCompanyStatsQuery,
  useLazyGetCompanyQuery,
} = statsServiceApi
