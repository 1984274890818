import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { store, persistedStore } from "./store"
import Splash from "./components/core/splash"
import { StyledEngineProvider } from "@mui/material/styles"
import { CustomToast } from "./components/core/customToast"
import "./i18n.js"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <React.Suspense fallback={<Splash />}>
        <CustomToast />
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </React.Suspense>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
)
