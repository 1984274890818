export enum UrlSlugType {
  // Public url
  LOGIN = "/login",
  REGISTER = "/sign-up",
  FORGOT_PASSWORD = "/forgot-password",
  ACTIVATE_EMPLOYEE = "/activate",

  // User Url
  HOME = "/home",
  ORDERS = "/orders",
  MENU = "/menu",
  PAYMENTS = "/payments",
  TEAM = "/team",
  SETTINGS = "/settings",

  // external links
  PRIVACY_POLICY = "https://digitalkantine.no/personvernpolicy-for-digital-kantine/"
}
