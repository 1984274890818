import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist"
import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import localStorage from "redux-persist/lib/storage"
import authReducer from "./slices/auth.slice"

// Api's
import { authServiceApi } from "../services/authService"
import { postalCodeServiceApi } from "../services/postalCodeService"
import { userServiceApi } from "../services/userService"
import { statsServiceApi } from "../services/statsService"
import { paymentInfoServiceApi } from "../services/paymentInfoService"
import { menuServiceApi } from "../services/menuService"
import { deliverInfoServiceApi } from "../services/deliverService"
import { orderServiceApi } from "../services/orderService"
import { notificationServiceApi } from "../services/notificationService"

const rootConfig = {
  key: "digital-kantine_web_v1",
  storage: localStorage,
  whitelist: ["auth"],
}

const persistedReducer = persistReducer(
  rootConfig,
  combineReducers({
    auth: authReducer,
    [authServiceApi.reducerPath]: authServiceApi.reducer,
    [postalCodeServiceApi.reducerPath]: postalCodeServiceApi.reducer,
    [userServiceApi.reducerPath]: userServiceApi.reducer,
    [statsServiceApi.reducerPath]: statsServiceApi.reducer,
    [paymentInfoServiceApi.reducerPath]: paymentInfoServiceApi.reducer,
    [menuServiceApi.reducerPath]: menuServiceApi.reducer,
    [deliverInfoServiceApi.reducerPath]: deliverInfoServiceApi.reducer,
    [orderServiceApi.reducerPath]: orderServiceApi.reducer,
    [notificationServiceApi.reducerPath]: notificationServiceApi.reducer,
  }),
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authServiceApi.middleware,
      postalCodeServiceApi.middleware,
      userServiceApi.middleware,
      statsServiceApi.middleware,
      paymentInfoServiceApi.middleware,
      menuServiceApi.middleware,
      deliverInfoServiceApi.middleware,
      orderServiceApi.middleware,
      notificationServiceApi.middleware,
    ] as any),
})

setupListeners(store.dispatch)

export const persistedStore = persistStore(store)
