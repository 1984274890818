import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"

export const postalCodeServiceApi = createApi({
  reducerPath: "postalCodeService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getPostalCodes: builder.query({
      query: ({ data }) => `${ApiSlug.GET_CODES}`,
      providesTags: ["codes"],
    }),
  }),
})

export const { useLazyGetPostalCodesQuery } = postalCodeServiceApi
