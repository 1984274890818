import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"
import { objectToSearchParams } from "../utils/helpers/common"

export const menuServiceApi = createApi({
  reducerPath: "menuService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getAdditions: builder.query({
      query: ({ data }) => ApiSlug.GET_ALL_ADDITIONS,
      providesTags: ["additions"],
    }),
    getMenuItems: builder.query({
      query: ({ data }) =>
        `${ApiSlug.GET_MENU_ITEMS}?${objectToSearchParams(data)}`,
      providesTags: ["menu_items"],
    }),
    getMenuItemsByDay: builder.query({
      query: ({ data }) =>
        `${ApiSlug.GET_MENU_ITEMS_BY_DAY}?${objectToSearchParams(data)}`,
      providesTags: ["menu_items_day"],
    }),
  }),
})

export const {
  useLazyGetAdditionsQuery,
  useLazyGetMenuItemsQuery,
  useLazyGetMenuItemsByDayQuery,
} = menuServiceApi
