import React from "react"
import { Navigate, useRoutes } from "react-router-dom"
import { UrlSlugType } from "../utils/enums/UrlSlug"

// Layout
const DashboardLayout = React.lazy(() => import("../layouts/private"))

// pages
const HomePage = React.lazy(() => import("../pages/private/employee/Home"))
const MyOrdersPage = React.lazy(
  () => import("../pages/private/employee/MyOrders"),
)
const MenuPage = React.lazy(() => import("../pages/private/employee/Menu"))
const PaymentsPage = React.lazy(
  () => import("../pages/private/employee/Payments"),
)
const SettingsPage = React.lazy(
  () => import("../pages/private/common/Settings"),
)

const EmployeeRoutes = () =>
  useRoutes([
    {
      element: <DashboardLayout />,
      children: [
        { path: UrlSlugType.HOME, element: <HomePage /> },
        { path: UrlSlugType.MENU, element: <MenuPage /> },
        { path: UrlSlugType.ORDERS, element: <MyOrdersPage /> },
        { path: UrlSlugType.PAYMENTS, element: <PaymentsPage /> },
        { path: UrlSlugType.SETTINGS, element: <SettingsPage /> },
      ],
    },
    { path: "*", element: <Navigate to={UrlSlugType.HOME} /> },
  ])

export default EmployeeRoutes
