import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"

export const notificationServiceApi = createApi({
  reducerPath: "notificationService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getNotifications: builder.query({
      query: ({ data }) => ApiSlug.GET_ALL_NOTIFICATIONS,
      providesTags: ["notifications"],
    }),
    clearNotifications: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CLEAR_NOTIFICATIONS,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["notifications"],
    }),
    MarkAsReadNotifications: builder.mutation({
      query: () => ({
        url: ApiSlug.MARK_READ_NOTIFICATIONS,
        method: "PUT",
      }),
      invalidatesTags: ["notifications"],
    }),
  }),
})

export const {
  useLazyGetNotificationsQuery,
  useClearNotificationsMutation,
  useMarkAsReadNotificationsMutation,
} = notificationServiceApi
