export const objectToSearchParams = (obj: any, ignore: string[] = []) => {
  const params = new URLSearchParams()

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!ignore.includes(key)) {
        params.append(key, obj[key])
      }
    }
  }

  return params.toString()
}

export function textFormat(value: any) {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export const isValidJWTStructure = (token: string) => {
  const parts = token.split(".")
  return parts.length === 3
}

export const removeStringFromArray = (
  arr: string[],
  strToRemove: string,
): string[] => {
  return arr.filter((item) => item !== strToRemove)
}
