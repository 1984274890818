import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"
// import { objectToSearchParams } from "../utils/helpers/common"

export const orderServiceApi = createApi({
  reducerPath: "orderService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getMyOrders: builder.query({
      query: ({ data }) =>
        `${ApiSlug.GET_ALL_ORDERS}?order_status_type=${data.order_status_type}&page_size=${data.page_size}&page=${data.page}&month_year=${data.monthYear}`,
      providesTags: ["orders"],
    }),

    getSingleOrder: builder.query({
      query: ({ data }) =>
        `${ApiSlug.GET_SINGLE_ORDER}?order_date=${data.order_date}`,
      providesTags: ["order"],
    }),

    oneTimeOrder: builder.mutation({
      query: (data) => ({
        url: ApiSlug.ONE_TIME_ORDER,
        method: "POST",
        body: data,
      }),
    }),
    editOneTimeOrder: builder.mutation({
      query: (data) => ({
        url: ApiSlug.EDIT_ONE_TIME_ORDER.replace("{order_id}", data?.order_id),
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["orders"],
    }),
    cancelOneOrder: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CANCEL_ONE_TIME_ORDER.replace(
          "{order_id}",
          data?.order_id,
        ),
        method: "PUT",
      }),
      invalidatesTags: ["orders"],
    }),

    recurringOrder: builder.mutation({
      query: (data) => ({
        url: ApiSlug.RECURRING_ORDER,
        method: "POST",
        body: data,
      }),
    }),
    editRecurringOrder: builder.mutation({
      query: (data) => ({
        url: ApiSlug.EDIT_RECURRING_ORDER,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["orders"],
    }),
    cancelRecurringOrder: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CANCEL_RECURRING_ORDER.replace(
          "{recurring_order_id}",
          data?.recurring_order_id,
        ),
        method: "PUT",
      }),
      invalidatesTags: ["orders"],
    }),

    meetingOrder: builder.mutation({
      query: (data) => ({
        url: ApiSlug.MEETING_ORDER,
        method: "POST",
        body: data,
      }),
    }),
  }),
})

export const {
  useOneTimeOrderMutation,
  useCancelOneOrderMutation,
  useLazyGetMyOrdersQuery,
  useMeetingOrderMutation,
  useRecurringOrderMutation,
  useLazyGetSingleOrderQuery,
  useCancelRecurringOrderMutation,
  useEditOneTimeOrderMutation,
  useEditRecurringOrderMutation,
} = orderServiceApi
