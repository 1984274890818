import React from "react"
import { BrowserRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setAuth } from "./store/slices/auth.slice"
import PublicRoutes from "./routes/PublicRoutes"
import CompanyRoutes from "./routes/CompanyRoutes"
import EmployeeRoutes from "./routes/EmployeeRoutes"
import { useLoginMutation } from "./services/authService"
import { UserRole } from "./utils/enums"

function App() {
  const dispatch = useDispatch()
  const { data, isSuccess } = useLoginMutation({
    fixedCacheKey: "auth-login",
  })[1]

  const token = useSelector((state: any) => state.auth.token)
  const user = useSelector((state: any) => state.auth.user)

  const Routes = React.useMemo(() => {
    if (!token) return <PublicRoutes />
    if (user?.role === UserRole.Company) return <CompanyRoutes />
    return <EmployeeRoutes />
  }, [token, user])

  React.useEffect(() => {
    isSuccess && dispatch(setAuth(data?.data))
  }, [isSuccess, data, dispatch])

  return <BrowserRouter children={Routes} />
}

export default App
