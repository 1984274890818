import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"
// import { objectToSearchParams } from "../utils/helpers/common"

export const userServiceApi = createApi({
  reducerPath: "userService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    inviteEmployee: builder.mutation({
      query: (data) => ({
        url: ApiSlug.INVITE_EMPLOYEE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["employees"],
    }),

    changeEmail: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CHANGE_EMAIL,
        method: "PUT",
        body: data,
      }),
    }),
    changeEmailVerify: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CHANGE_EMAIL_VERIFY,
        method: "PUT",
        body: data,
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CHANGE_PASSWORD,
        method: "PUT",
        body: data,
      }),
    }),
    changeName: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CHANGE_NAME,
        method: "PUT",
        body: data,
      }),
    }),
    changeDeliveryAddress: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CHANGE_ADDRESS,
        method: "PUT",
        body: data,
      }),
    }),
    changeAllowanceRequest: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CHANGE_ALLOWANCE,
        method: "PUT",
        body: data,
      }),
    }),
    getStaffs: builder.query({
      query: ({ data }) => {
        return {
          url: `${ApiSlug.GET_COMPANY_STAFFS}?page_size=${data.page_size}&page=${data.page}&name=${data.name}`,
        }
      },
      providesTags: ["employees"],
    }),
    getUserInfo: builder.query({
      query: () => {
        return {
          url: ApiSlug.USER_INFO,
        }
      },
      providesTags: ["user_info"],
    }),
    disableEmployee: builder.mutation({
      query: (data) => ({
        url: `${ApiSlug.DISABLE_EMPLOYEE}/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["employees"],
    }),

    markAsComplete: builder.mutation({
      query: () => ({
        url: ApiSlug.MARK_COMPLETE,
        method: "PUT",
      }),
    }),
  }),
})

export const {
  useChangeEmailMutation,
  useChangeEmailVerifyMutation,
  useChangePasswordMutation,
  useChangeNameMutation,
  useChangeDeliveryAddressMutation,
  useLazyGetStaffsQuery,
  useChangeAllowanceRequestMutation,
  useDisableEmployeeMutation,
  useLazyGetUserInfoQuery,
  useInviteEmployeeMutation,
  useMarkAsCompleteMutation,
} = userServiceApi
