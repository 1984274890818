import React from "react"
import { Transition } from "@headlessui/react"
import { Toaster, ToastIcon, toast, resolveValue } from "react-hot-toast"
import { CloseThickIcon } from "../../../assets/icons"

export const CustomToast = () => {
  return (
    <Toaster
      position="top-center"
      toastOptions={{
        success: {
          iconTheme: {
            primary: "#004335",
            secondary: "#EBEBEB",
          },
        },
        error: {
          iconTheme: {
            primary: "#5D1A15",
            secondary: "#EBEBEB",
          },
        },
      }}
    >
      {(t) => {
        return (
          <Transition
            appear
            show={t.visible}
            className="transform flex"
            enter="transition-all duration-150"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
          >
            <div
              className={`${
                t.type === "success" ? "bg-primary-1-100" : "bg-reds-100"
              } rounded-xl h-14 min-w-48 overflow-hidden flex justify-between items-center`}
            >
              <div className="p-[18px] flex gap-[18px]">
                <ToastIcon toast={t} />
                <p
                  className={`${
                    t.type === "success"
                      ? "text-primary-1-800"
                      : "text-reds-800"
                  }text-primary-1-800 font-medium text-base`}
                >
                  {resolveValue(t.message, t)}
                </p>
              </div>
              <div
                className={`${
                  t.type === "success" ? "bg-primary-1-800" : "bg-reds-800"
                } bg-primary-1-800 h-full text-white w-14 flex items-center justify-center`}
              >
                <div
                  onClick={() => toast.dismiss(t.id)}
                  className="cursor-pointer"
                >
                  <CloseThickIcon className="fill-white" />
                </div>
              </div>
            </div>
          </Transition>
        )
      }}
    </Toaster>
  )
}
