import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"
// import { objectToSearchParams } from "../utils/helpers/common"

export const authServiceApi = createApi({
  reducerPath: "authService",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: ApiSlug.LOGIN,
        method: "POST",
        body: credentials,
      }),
    }),

    signUp: builder.mutation({
      query: (data) => ({
        url: ApiSlug.SIGN_UP,
        method: "POST",
        body: data,
      }),
    }),

    activateEmployee: builder.mutation({
      query: (data) => ({
        url: ApiSlug.ACTIVATE_EMPLOYEE,
        method: "POST",
        body: data,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (data) => ({
        url: ApiSlug.FORGOT_PASSWORD,
        method: "POST",
        body: data,
      }),
    }),

    verifyOTP: builder.mutation({
      query: (data) => ({
        url: ApiSlug.VERIFY_OTP,
        method: "POST",
        body: data,
      }),
    }),

    resetPassword: builder.mutation({
      query: (data) => ({
        url: ApiSlug.RESET_PASSWORD,
        method: "POST",
        body: data,
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useVerifyOTPMutation,
  useResetPasswordMutation,
  useActivateEmployeeMutation,
} = authServiceApi
