import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { UserInfo } from "../../utils/types"

export type AuthStateType = {
  token?: string
  user?: UserInfo
}

const initialState: AuthStateType = {
  token: "",
  user: undefined,
}

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<any>): AuthStateType => ({
      ...action.payload,
    }),
    setUser: (state, action: PayloadAction<any>): AuthStateType => ({
      ...state,
      user: action.payload,
    }),
    setToken: (state, action: PayloadAction<any>): AuthStateType => ({
      ...state,
      token: action.payload,
    }),
    logOut: () => initialState,
  },
})

export const { setAuth, setUser, setToken, logOut } = authSlice.actions
export default authSlice.reducer
