import React from "react"
import { Navigate, useRoutes } from "react-router-dom"
import { UrlSlugType } from "../utils/enums/UrlSlug"

// Layout
const AuthLayout = React.lazy(() => import("../layouts/auth"))

// pages
const LoginPage = React.lazy(() => import("../pages/public/Login"))
const RegisterPage = React.lazy(() => import("../pages/public/Register"))
const ActivateEmployeePage = React.lazy(
  () => import("../pages/public/ActivateEmployee"),
)
const ForgotPasswordPage = React.lazy(
  () => import("../pages/public/ForgotPassword"),
)

const PublicRoutes = () =>
  useRoutes([
    {
      element: <AuthLayout />,
      children: [
        { path: UrlSlugType.LOGIN, element: <LoginPage /> },
        { path: UrlSlugType.REGISTER, element: <RegisterPage /> },
        { path: UrlSlugType.FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
        {
          path: UrlSlugType.ACTIVATE_EMPLOYEE,
          element: <ActivateEmployeePage />,
        },
      ],
    },
    { path: "*", element: <Navigate to={UrlSlugType.LOGIN} /> },
  ])

export default PublicRoutes
