import React from "react"

const Splash = () => {
  return (
    <div className="bg-white grid h-screen place-items-center">
      <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-[#00876B]" />
    </div>
  )
}

export default Splash
