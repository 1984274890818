import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery from "./api"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { ApiSlug } from "../utils/enums/ApiSlug"

export const paymentInfoServiceApi = createApi({
  reducerPath: "paymentInfoService",
  baseQuery: baseQuery,
  tagTypes: ["payment_info", "payments"],
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    getPayments: builder.query({
      query: ({ data }) => ApiSlug.GET_PAYMENTS,
      providesTags: ["payments"],
    }),

    getCompanyPayments: builder.query({
      query: ({ data }) => ApiSlug.GET_COMPANY_PAYMENTS,
      providesTags: ["payments"],
    }),

    getPaymentInfo: builder.query({
      query: ({ data }) => ApiSlug.NEW_EDIT_PAYMENT_INFO,
      providesTags: ["payment_info"],
    }),
    createSubscription: builder.mutation({
      query: (data) => ({
        url: ApiSlug.CREATE_SUBSCRIPTION,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["payment_info"],
    }),
    deletePaymentInfo: builder.mutation({
      query: () => ({
        url: ApiSlug.DELETE_SUBSCRIPTION,
        method: "DELETE",
      }),
      invalidatesTags: ["payment_info"],
    }),
  }),
})

export const {
  useLazyGetPaymentInfoQuery,
  useDeletePaymentInfoMutation,
  useCreateSubscriptionMutation,
  useLazyGetPaymentsQuery,
  useLazyGetCompanyPaymentsQuery,
} = paymentInfoServiceApi
