export enum UserRole {
  Company = "company",
  Employee = "employee",
}

export enum MenuItemSection {
  Sandwiches = "sandwiches",
  Salads = "salads",
  Wraps = "wraps",
  Additions = "additions",
}

export enum AdditionType {
  Snack = "snack",
  Beverage = "beverage",
}

export enum Bool {
  True = "1",
  False = "0",
}

export enum OrderType {
  OneTimeOrder = "one_time_order",
  RecurringOrder = "recurring_order",
}

export enum OrderStatus {
  NotStarted = "not_started",
  Processing = "processing",
  Delivered = "delivered",
  Cancelled = "cancelled",
}

export enum AllowanceType {
  Percentage = "percentage",
  DailySetAmount = "daily_set_amount",
}
